import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Layout } from '../../components/shared/layout';
import { Page } from '../../components/shared/Page';
import { BusinessesBanner } from '../../components/modules/BusinessesPage';
import { BusinessesPageHeader } from '../../components/modules/BusinessesPage/BusinessesPageHeader';
import { DownloadMerchantsApp } from '../../components/modules/common/DownloadHandCarryApp';
import { JapanShippingStatistics } from '../../components/modules/BusinessesPage/JapanShippingStatistics';
import { BusinessNavigation } from '../../components/modules/common/BusinessNavigation';
import { LocationProp } from '../../components/shared/props/LocationProp';
import { NoIntegrationList } from '../../components/modules/BusinessesPage/NoIntegrationList';
import { BusinessesBenefitList } from '../../components/modules/BusinessesPage/BusinessesBenefitList';
import { WhyHandCarry } from '../../components/modules/BusinessesPage/WhyHandCarry'

const StyledBusinessesPage = styled(Layout)`
  text-align: center;
`;

const BusinessesPage = ({
  location: { pathname },
}) => (
  <StyledBusinessesPage headerTransparent>
    <Page>
      <BusinessesBanner />
      <BusinessesPageHeader />
      <BusinessesBenefitList />
      <NoIntegrationList />
      <WhyHandCarry />
      <JapanShippingStatistics />
      <BusinessNavigation pathname={pathname} />
      <DownloadMerchantsApp />
    </Page>
  </StyledBusinessesPage>
);

BusinessesPage.propTypes = {
  location: PropTypes.shape(LocationProp).isRequired,
};

export default BusinessesPage;
