import React from 'react';
import { ListSection } from '../../../shared/ListSection';
import BoxImage from '../images/box.svg';
import PeopleImage from '../images/people.svg';
import TrainImage from '../images/train.svg';
import { JapanShippingStatisticsListItem } from './JapanShippingStatisticsListItem';
import { PageHeader, PageTitle } from '../../../shared/Page';

const data = [
  {
    image: BoxImage,
    title: '4,000,000,000',
    description: 'Over 4bn parcels were sent in Japan in 2016. 90% of those parcels were from businesses to consumers.',
  },
  {
    image: PeopleImage,
    title: '38,000,000',
    description: '38m people live within the Tokyo greater metropolitan area. That’s a lot of pairs of helping hands.',
  },
  {
    image: TrainImage,
    title: '20,000,000',
    description: '20m of those 38m people commute via trains each and every day.',
  },
];

const JapanShippingStatistics = () => (
  <div>
    <PageHeader>
      <PageTitle>Japan's shipping in numbers</PageTitle>
    </PageHeader>
    <ListSection
      data={data}
      component={JapanShippingStatisticsListItem}
    />
  </div>
);

export { JapanShippingStatistics };