import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledWhyHandCarryContentDetails = styled.div`
`;

const StyledParagraph = styled.p`
  ${({ noMarginTop }) => noMarginTop && 'margin-top: 0;'}
`;

const WhyHandCarryContentDetails = ({ className }) => (
  <StyledWhyHandCarryContentDetails className={className}>
    <StyledParagraph noMarginTop>
      When we started developing the idea, we wanted a model
      where everyone involved wins. In business, generally that
      isn’t possible.
    </StyledParagraph>

    <StyledParagraph>
      From the commuters who earn extra money, and the
      businesses that save money, the environment is clearly
      the biggest winner here. Something we’re so proud of.
    </StyledParagraph>

    <StyledParagraph>
      Even the existing logistics providers will benefit from the
      relief from their labour shortage, and being able to focus
      more on larger, and further deliveries ensuring increased
      profitability.
    </StyledParagraph>
  </StyledWhyHandCarryContentDetails>
);

WhyHandCarryContentDetails.defaultProps = {
  className: null,
};

WhyHandCarryContentDetails.propTypes = {
  className: PropTypes.string,
};

export { WhyHandCarryContentDetails };