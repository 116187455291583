import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SCREEN_SIZE, STYLES } from '../../../../style';

const StyledNoIntegrationListItem = styled.div`
  padding: 3rem 0;
  ${SCREEN_SIZE.LG} {
    padding: 0 3rem;
  }
`;

const NoIntegrationPointImage = styled.img`
`;

const NoIntegrationTitle = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1.6rem;
  font-weight: 600;
  font-size: ${STYLES.FONT_SIZE_LG};
  ${SCREEN_SIZE.LG} {
    text-align: left;
  }
`;

const NoIntegrationDescription = styled.div`
  text-align: center;
  color: ${STYLES.COLOR_DOVE_GRAY};
  ${SCREEN_SIZE.LG} {
    text-align: left;
  }
`;

const ImageContainer = styled.div`
  text-align: center;
  ${SCREEN_SIZE.LG} {
    text-align: left;
  }
`;

const NoIntegrationListItem = ({
  data: {
    image,
    title,
    description,
  },
}) => (
  <StyledNoIntegrationListItem>
    <ImageContainer>
      <NoIntegrationPointImage
        src={image}
      />
    </ImageContainer>
    <NoIntegrationTitle>{title}</NoIntegrationTitle>
    <NoIntegrationDescription>
      {description}
    </NoIntegrationDescription>
  </StyledNoIntegrationListItem>
);

NoIntegrationListItem.propTypes = {
  // eslint-disable-next-line
  data: PropTypes.object.isRequired,
};

export { NoIntegrationListItem };