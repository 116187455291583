import React from 'react';
import styled from 'styled-components';
import { PageHeader, PageTitle } from '../../../shared/Page';
import { WhyHandCarryContentDetails } from './WhyHandCarryContentDetails';
import { WhyHandCarryList } from './WhyHandCarryList';
import { Centralized } from '../../../shared/layout';
import { SCREEN_SIZE, STYLES } from '../../../../style'

const StyledWhyHandCarry = styled.div`
`;

const WhyHandCarryContent = styled.div`
  text-align: center;
  ${SCREEN_SIZE.LG} {
    display: flex;
  }
`;

const StyledWhyHandCarryContentDetails = styled(WhyHandCarryContentDetails)`
  line-height: ${STYLES.LINE_HEIGHT_LG};
  text-align: left
  ${SCREEN_SIZE.LG} {
    line-height: ${STYLES.LINE_HEIGHT_MD};
    flex: 1;
    padding: 0 2rem;
    text-align: left;
  }
`;

const StyledWhyHandCarryList = styled(WhyHandCarryList)`
  margin: 3rem auto 0;
  ${SCREEN_SIZE.LG} {
    flex: 1;
    padding: 0 2rem;
    margin: 0;
  }
`;

const WhyHandCarry = () => (
  <StyledWhyHandCarry>
    <Centralized>
      <PageHeader>
        <PageTitle>Why should I use Hand Carry</PageTitle>
      </PageHeader>
      <WhyHandCarryContent>
        <StyledWhyHandCarryContentDetails />
        <StyledWhyHandCarryList />
      </WhyHandCarryContent>
    </Centralized>
  </StyledWhyHandCarry>
);

export { WhyHandCarry };