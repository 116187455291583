import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { SCREEN_SIZE, STYLES } from '../../../../style';

const StyledJapanShippingStatisticsListItem = styled.div`
  padding: 2rem 0;
  text-align: center;
  flex: 1;
  ${SCREEN_SIZE.LG} {
    padding: 0 1rem;
  }
`;

const JapanShippingStatisticsImage = styled.img`
  height: 7.5rem;
`;

const JapanShippingStatisticsTitle = styled.h5`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const JapanShippingStatisticsDescription = styled.div`
  color: ${STYLES.COLOR_DOVE_GRAY};
`;

const ImageContainer = styled.div`
  
`;

const JapanShippingStatisticsListItem = ({
  data: {
    image,
    title,
    description,
  },
}) => (
  <StyledJapanShippingStatisticsListItem>
    <ImageContainer>
      <JapanShippingStatisticsImage
        src={image}
      />
    </ImageContainer>
    <JapanShippingStatisticsTitle>{title}</JapanShippingStatisticsTitle>
    <JapanShippingStatisticsDescription>{description}</JapanShippingStatisticsDescription>
  </StyledJapanShippingStatisticsListItem>
);

JapanShippingStatisticsListItem.propTypes = {
  data: PropTypes.object.isRequired,
};

export { JapanShippingStatisticsListItem };