import React from 'react';
import styled from 'styled-components';
import {
  Banner, BannerContent, BannerDescription, BannerContentItem, BannerTitle, WatchVideoBannerButton,
} from '../../shared/Banner'
import { PageImage } from '../../shared/PageImage';
import BusinessesBannerIconSource from './images/businessesBannerIcon.svg';
import { SCREEN_SIZE, STYLES } from '../../../style';

const StyledBusinessesBanner = styled(Banner)`
`;

const BannerImage = styled(PageImage)`
  ${SCREEN_SIZE.LG} {
    transform: translateY(22px);
  }
`;

const BusinessesBanner = () => (
  <StyledBusinessesBanner color={STYLES.PAGE_BUSINESSES_BANNER_COLOR}>
    <BannerContent>
      <BannerContentItem>
        <BannerImage src={BusinessesBannerIconSource} />
      </BannerContentItem>
      <BannerContentItem>
        <BannerTitle>All businesses big or small</BannerTitle>
        <BannerDescription>
          No matter what size or shape your businesses is, as
          long as you ship parcels we can help. We provide lightning
          fast ECO shipping for less than regular shipping.
        </BannerDescription>
        <WatchVideoBannerButton />
      </BannerContentItem>
    </BannerContent>
  </StyledBusinessesBanner>
);

export { BusinessesBanner };