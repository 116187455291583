import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { WhyHandCarryListItem } from './WhyHandCarryListItem';
import { SCREEN_SIZE } from '../../../../style'

const StyledWhyHandCarryList = styled.div`
  display: inline-block;
  ${SCREEN_SIZE.LG} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const items = [
  'The fastest shipping available',
  'Cheaper than traditional shipping',
  'Environmentally conscious',
  'Commercially respectful',
  'No contracts or minimum spends',
  'Simple and easy',
];

const WhyHandCarryList = ({ className }) => (
  <StyledWhyHandCarryList className={className}>
    { items.map(item => <WhyHandCarryListItem key={item}>{item}</WhyHandCarryListItem>)}
  </StyledWhyHandCarryList>
);

WhyHandCarryList.defaultProps = {
  className: null,
};

WhyHandCarryList.propTypes = {
  className: PropTypes.string,
};

export { WhyHandCarryList };