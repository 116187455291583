import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TickImageSource from '../images/tick.svg';

const StyledWhyHandCarryListItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: .8rem;
`;

const TickImage = styled.img.attrs({
  src: TickImageSource,
})`margin-right: 1rem;`;

const WhyHandCarryListItem = ({ children }) => (
  <StyledWhyHandCarryListItem>
    <TickImage /> {children}
  </StyledWhyHandCarryListItem>
);

WhyHandCarryListItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export { WhyHandCarryListItem };