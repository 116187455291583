import React from 'react';
import { ListSection } from '../../../shared/ListSection';
import Point1Image from '../images/Point1.svg';
import Point2Image from '../images/Point2.svg';
import Point3Image from '../images/Point3.svg';
import { NoIntegrationListItem } from './NoIntegrationListItem';
import { PageHeader, PageTitle } from '../../../shared/Page';

const data = [
  {
    image: Point1Image,
    title: 'Download',
    description: 'Look for the Hand Carry app in the Apple iTunes store or Google Play Store.',
  },
  {
    image: Point2Image,
    title: 'Register',
    description: 'After opening the app, register and setup your business with a few basic details.',
  },
  {
    image: Point3Image,
    title: 'Send',
    description: 'Once you’re registered and are all setup, simply submit a delivery and Hand Carry will do the rest.',
  },
];

const NoIntegrationList = () => (
  <div>
    <PageHeader>
      <PageTitle>No integration, no contracts</PageTitle>
    </PageHeader>
    <ListSection component={NoIntegrationListItem} data={data} />
  </div>
);

export { NoIntegrationList };