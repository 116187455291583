import React from 'react';
import ClockImage from './images/clock.svg';
import GiftImage from './images/gift.svg';
import { TwoColumnList } from '../../shared/TwoColumnList';

const items = [
  {
    image: ClockImage,
    title: 'Cheaper, faster and more efficient',
    description: 'With no trucks, no depots and no schedules, Hand Carry delivers with lightning speed and efficiency.',
  },
  {
    image: GiftImage,
    title: 'Give your customers the best for less',
    description: 'You can offer your customers same day delivery at a lower price point than regular shipping.',
  },
];

const BusinessesBenefitList = () => <TwoColumnList items={items} />;

export { BusinessesBenefitList };